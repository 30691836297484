<template>
    <path d="m485.5 384h42.5c8.8 0 16-7.2 16-16v-42.5c0-8.5-3.4-16.6-9.4-22.6l-231.4-231.4-71.7 71.7 231.4 231.4c6 6 14.1 9.4 22.6 9.4zm-276.6-263.4 71.7-71.8-41.8-41.8c-9.4-9.3-24.5-9.3-33.9 0l-37.9 37.9c-9.3 9.4-9.3 24.4 0 33.8l41.9 41.9zm-72.9 303.4h16c4.4 0 8-3.6 8-8v-16.1c23.6-0.6 42.7-20.5 42.7-45.1 0-20-13-37.8-31.6-43.4l-45-13.5c-5.2-1.5-8.8-6.8-8.8-12.7 0-7.3 5.3-13.2 11.8-13.2h28.1c4.5 0 9 1.3 12.8 3.7 1.4 0.9 3.1 1.4 4.8 1.4 2 0 3.9-0.7 5.4-2.1l11.8-11.2c3.2-3 3.4-8.1 0.3-11.3-0.3-0.3-0.6-0.6-0.9-0.8-9.1-6.8-20-10.8-31.4-11.3v-16.4c0-4.4-3.6-8-8-8h-16c-4.4 0-8 3.6-8 8v16.1c-23.6 0.6-42.7 20.5-42.7 45.1 0 20 13 37.8 31.6 43.4l45 13.5c5.2 1.5 8.8 6.8 8.8 12.7 0 7.3-5.3 13.2-11.8 13.2h-28.1c-4.5 0-8.9-1.3-12.8-3.7-1.4-0.9-3.1-1.4-4.8-1.4-2 0-3.9 0.7-5.4 2.1l-11.8 11.2c-3.2 3-3.4 8.1-0.3 11.3 0.3 0.3 0.6 0.6 0.9 0.8 9.1 6.8 20 10.8 31.4 11.3v16.4c0 4.4 3.6 8 8 8zm120-120h91l-48-48h-43c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16zm-16 64c0 8.8 7.2 16 16 16h171l-48-48h-123c-8.8 0-16 7.2-16 16v16zm368-240h-203l48 48h139v288h-544v-288h171.1l-48-48h-139.1c-17.7 0-32 14.3-32 32v320c0 17.7 14.3 32 32 32h576c17.7 0 32-14.3 32-32v-320c0-17.7-14.3-32-32-32z"/>
</template>

<script>
export default {
    setup(props, { emit }) {
        emit('dimensions', { width: 640, height: 512 });
    },
    emits: ['dimensions'],
};
</script>
